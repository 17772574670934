import React, { useState, useContext } from 'react';
import axios from 'axios';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [auth, setAuth] = useState(null); //contains current user details
  const [company, setCompany] = useState(null); // contains current company  based on logged user
  const [settings, setSettings] = useState(null); // contains settings based on current company
  const [userPerm, setUserPerm] = useState({
    canCreate: true,
    canUpdate: true,
    canDelete: true,
    canUpdateSettings: true,
    viewSalesReports: true,
  }); //user permission based on logged user

  const salesMenuRef = React.useRef(null);

  React.useEffect(() => {
    if (auth && settings) {
      const role = auth.role;

      const doc = {
        canCreate: settings.canCreate.includes(role),
        canUpdate: settings.canUpdate.includes(role),
        canDelete: settings.canDelete.includes(role),
        canUpdateSettings: settings.canUpdateSettings.includes(role),
        viewSalesReports: settings.viewSalesReports.includes(role),
      };

      setUserPerm(doc);
    }
  }, [settings, auth]);

  React.useEffect(() => {
    if (auth) {
      fetchCurrentCompany();
      fetchSettings();
    }
  }, [auth]);

  //USER
  async function fetchCurrentUser() {
    const res = await axios.get('/users/current_user', {
      credentials: 'include',
    });

    setAuth(res ? res.data : false);
    return res ? true : false;
  }

  async function fetchCurrentCompany() {
    const res = await axios.get('/users/company', {
      credentials: 'include',
    });
    setCompany(res ? res.data : false);
    return res ? true : false;
  }

  async function fetchSettings() {
    const res = await axios.get('api/settings', {
      credentials: 'include',
    });

    setSettings(res.data.data);
    return res ? true : false;
  }

  async function logoutUser() {
    await axios.get('/users/signout');
    setAuth(false);
  }

  async function signInUser(data) {
    const res = await axios.post('/users/signin', data);
    return res;
  }

  return (
    <AppContext.Provider
      value={{
        auth,
        company,
        settings,
        userPerm,
        salesMenuRef,
        fetchCurrentUser,
        fetchCurrentCompany,
        fetchSettings,
        logoutUser,
        signInUser,
        setAuth,
      }}>
      {children}
    </AppContext.Provider>
  );
};

//make sure use

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
