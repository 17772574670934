import React from 'react';
//import PropTypes from 'prop-types';

import { withTheme } from '@mui/styles';

function LoadingApp(props) {
  //const { theme, buttonText } = props;

  return <div>Loading...</div>;
}

LoadingApp.propTypes = {
  //buttonText: PropTypes.string,
  //styles: PropTypes.object.isRequired,
  //color: PropTypes.string.isRequired,
};

export default withTheme(LoadingApp);
