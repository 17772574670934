import React, { Fragment, useEffect } from 'react';
import { Box, Stack, Typography, Button, Grid, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FilledTextField from '../common/components/Input/FilledTextField';
import FilledPassword from '../common/components/Input/FilledPassword';
import { AppContext, useGlobalContext } from '../globalAppContext';
import eFlozLogo from '.././common/images/eflozlogo.png';

function Login(props) {
  //const { theme } = props;
  const { signInUser, fetchCurrentUser } = useGlobalContext(AppContext);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const handleRegistration = async (data, event) => {
    event.preventDefault();

    let res = await signInUser(data);

    if (res.data.message === 'success') {
      const res = await fetchCurrentUser();
      if (res) {
        navigate('/salesBill');
      }
    } else {
      setAlertMessage(res.data.message);
      setAlertOpen(true);
    }
    // selectedItem === null
    //   ? createMutation.mutate(data)
    //   : updateMutation.mutate(data);
    // onSuccess(); //to close dialog
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .max(15, 'maximum length 15')
      .required('Please Enter Username'),
    password: Yup.string()
      .max(15, 'maximum length 15')
      .required('Please Enter Password'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const {
    control,
    handleSubmit,

    formState: { errors, isValidating },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    // console.log(errors);
    // console.log(Object.keys(errors).length);

    if (Object.keys(errors).length === 0) {
      setAlertOpen(false);
    } else {
      setAlertOpen(true);
    }
  }, [errors, isValidating]);

  //console.log(errors);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        width: '100%',
        height: '95%',
      }}>
      <Box
        sx={{
          display: 'flex',
          m: 0,
          p: 0,
          alignItems: 'left',
          width: '210px',
          height: '90vh',
          overflow: 'hidden',
          //bgcolor: 'lightgray',
        }}>
        <Box sx={{ width: '203px', height: '122px', m: 3 }}>
          <img width={110} height={80} src={eFlozLogo} alt={'eFloz Logo'}></img>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          m: 0,
          p: 0,
          alignItems: 'center',
          width: '80%',
          height: '90vh',
          overflow: 'hidden',
          //bgcolor: 'lightblue',
        }}>
        <Grid container sx={{ width: 400, m: 'auto', p: 'auto' }}>
          <Grid
            item
            xs={12}
            sx={{
              ml: 'auto',
              mr: 'auto',
              mb: 10,
              P: 0,
              alignItems: 'center',
              textAlign: 'center',
              overflow: 'hidden',
            }}>
            <Typography
              sx={{
                fontFamily: 'Inter, sans-serif',
                //lineHeight: 2,
                fontWeight: 600,
                width: '100%',
              }}
              variant='h3'>
              BillZ
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(handleRegistration)} id='loginForm'>
              <Stack sx={{ borderColor: 'blue', border: 0 }}>
                <FilledTextField
                  //fullwidth
                  placeholder='Username'
                  name='username'
                  control={control}
                  //errors={errors}
                  //sx={{ width: '100%', m: 2, p: 2 }}
                />
                <FilledPassword
                  //fullwidth
                  placeholder='Password'
                  name='password'
                  control={control}
                  //sx={{ width: '100%', m: 2, p: 2 }}
                  //errors={errors}
                />
                <Button
                  color='secondary'
                  fullwidth
                  type='submit'
                  variant='contained'
                  size='medium'
                  sx={{
                    ml: 0,
                    mr: 0,
                    mt: 5,
                    mb: 0,
                    pt: 2,
                    pb: 2,
                    pl: 0,
                    pr: 0,
                  }}>
                  Login
                </Button>
              </Stack>
              <Box
                sx={{
                  width: '100%',
                  mt: 10,
                  display: alertOpen ? 'flex' : 'none',
                }}>
                {/* <Typography variant='caption' color='warning'>
                  {errors.username
                    ? errors.username.message
                    : errors.password
                    ? errors.password.message
                    : ''}
                </Typography> */}
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='error'>
                    {errors.username
                      ? errors.username.message
                      : errors.password
                      ? errors.password.message
                      : alertMessage}
                  </Alert>
                </Stack>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Login.propTypes = {
  //selectDashboard: PropTypes.func.isRequired,
};
export default Login;
