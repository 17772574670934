import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Routing from './Routing';
import { Box, Grid } from '@mui/material';

const styles = (theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    width: '98vw',
    height: '96vh',
    //backgroundColor: 'red',
    margin: '0px',
    padding: '0px',
  },
});

function Main(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        {/* <Stack>
          <FilledTextField
            //fullwidth
            placeholder='Type Something...'
            name='username'
            //control={control}
            //errors={errors}
            //sx={{ width: '100%', m: 2, p: 2 }}
          />
          <FilledPassword
            //fullwidth
            placeholder='Password'
            name='password'
            //control={control}
            //sx={{ width: '100%', m: 2, p: 2 }}
            //errors={errors}
          />
          <Button
            fullwidth
            type='submit'
            variant='contained'
            size='medium'
            sx={{ m: 2, p: 2, mt: 5 }}>
            Login
          </Button>
        </Stack> */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            flexGrow: 1,
            // display: 'flex',
            // flexDirection: 'row',
            // m: 0,
            // p: 0,
            // alignItems: 'center',
            //backgroundColor: theme.palette.common.black,
          }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            {/* <Grid
              item
              xs={0}
              sm={0}
              md={4}
              lg={6}
              sx={{
                //border: 1,
                height: '100%',
                display: { xs: 'none', sm: 'none', md: 'flex' },
              }}>
              <img
                src={loginimage}
                alt={'Green fuel'}
                height={'100%'}
                width={'100%'}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={8} lg={6}>
              <Routing />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Routing />
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(memo(Main));
