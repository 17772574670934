import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// colors
const primary = '#1976d2';
const secondary = '#673ab7';
const black = '#343a40';
const darkBlack = 'rgb(36, 40, 44)';
//const background = '#f5f5f5';
const warningLight = 'rgba(253, 200, 69, .3)';
const warningMain = 'rgba(253, 200, 69, .5)';
const warningDark = 'rgba(253, 200, 69, .7)';

const errorLight = '#ef5350';
const errorMain = '#c62828'; // '#d32f2f';
const errorDark = '#c62828';

const successLight = '#4caf50';
const successMain = '#2e7d32';
const successDark = '#1b5e20';

// border
const borderWidth = 2;
const borderColor = 'rgba(0, 0, 0, 0.13)';

// breakpoints
// const xl = 1536;
// const lg = 1200;
// const md = 900;
const sm = 600;
//const xs = 0;

// spacing
const spacing = 4;

const theme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    light: { main: secondary },
    common: {
      black,
      darkBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    error: {
      light: errorLight,
      main: errorMain,
      dark: errorDark,
    },
    success: {
      light: successLight,
      main: successMain,
      dark: successDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      // default: background,
      default: '#FFFFFF', //!App Background Color
    },
    spacing: spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  components: {
    MuiExpansionPanel: {
      root: {
        position: 'static',
      },
    },
    MuiTableCell: {
      root: {
        boxShadow: 0,
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       html: {
  //         WebkitFontSmoothing: 'auto',
  //       },
  //       /**
  //        * Disable the focus outline, which is default on some browsers like
  //        * chrome when focusing elements
  //        */
  //       '*:focus': {
  //         outline: 0,
  //       },
  //       '.text-white': {
  //         color: theme.palette.common.black,
  //       },
  //       '.listItemLeftPadding': {
  //         paddingTop: `${spacing(1.75)}px !important`,
  //         paddingBottom: `${spacing(1.75)}px !important`,
  //         paddingLeft: `${spacing(4)}px !important`,
  //         [breakpoints.down('sm')]: {
  //           paddingLeft: `${spacing(4)}px !important`,
  //         },
  //         '@media (max-width:  420px)': {
  //           paddingLeft: `${spacing(1)}px !important`,
  //         },
  //       },
  //       '.container': {
  //         width: '100%',
  //         paddingRight: spacing(4),
  //         paddingLeft: spacing(4),
  //         marginRight: 'auto',
  //         marginLeft: 'auto',
  //         [breakpoints.up('sm')]: {
  //           maxWidth: 540,
  //         },
  //         [breakpoints.up('md')]: {
  //           maxWidth: 720,
  //         },
  //         [breakpoints.up('lg')]: {
  //           maxWidth: 1170,
  //         },
  //       },
  //       '.row': {
  //         display: 'flex',
  //         flexWrap: 'wrap',
  //         marginRight: -spacing(2),
  //         marginLeft: -spacing(2),
  //       },
  //       '.container-fluid': {
  //         width: '100%',
  //         paddingRight: spacing(2),
  //         paddingLeft: spacing(2),
  //         marginRight: 'auto',
  //         marginLeft: 'auto',
  //         maxWidth: 1370,
  //       },
  //       '.lg-mg-top': {
  //         marginTop: `${spacing(20)}px !important`,
  //         [breakpoints.down('md')]: {
  //           marginTop: `${spacing(18)}px !important`,
  //         },
  //         [breakpoints.down('sm')]: {
  //           marginTop: `${spacing(16)}px !important`,
  //         },
  //         [breakpoints.down('xs')]: {
  //           marginTop: `${spacing(14)}px !important`,
  //         },
  //       },
  //       '.lg-mg-bottom': {
  //         marginBottom: `${spacing(20)}px !important`,
  //         [breakpoints.down('md')]: {
  //           marginBottom: `${spacing(18)}px !important`,
  //         },
  //         [breakpoints.down('sm')]: {
  //           marginBottom: `${spacing(16)}px !important`,
  //         },
  //         [breakpoints.down('xs')]: {
  //           marginBottom: `${spacing(14)}px !important`,
  //         },
  //       },
  //       '.lg-p-top': {
  //         paddingTop: `${spacing(20)}px !important`,
  //         [breakpoints.down('md')]: {
  //           paddingTop: `${spacing(18)}px !important`,
  //         },
  //         [breakpoints.down('sm')]: {
  //           paddingTop: `${spacing(16)}px !important`,
  //         },
  //         [theme.breakpoints.down('xs')]: {
  //           paddingTop: `${theme.spacing(14)}px !important`,
  //         },
  //       },
  //     },
  //   },
  // },
});

export default responsiveFontSizes(theme);
