import React, { lazy, useEffect, Suspense, Fragment } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from './theme';
import { Navigate } from 'react-router-dom';
import LoggedOutComponent from './logged_out/Main';
import { AppContext, useGlobalContext } from './globalAppContext';

import LoadingApp from './common/components/LoadingApp';
const LoggedInComponent = lazy(() => import('./components/Main'));

function App(props) {
  const { auth, fetchCurrentUser } = useGlobalContext(AppContext);

  const ProtectedRoute = ({ children }) => {
    return auth ? children : <Navigate to='/login' />;
  };

  const CheckLogin = ({ children }) => {
    return auth ? <Navigate to='/' /> : children;
  };

  useEffect(() => {
    document.title = 'BillZ';

    let res;
    (async () => {
      res = await fetchCurrentUser();
    })();
  }, []);

  if (auth === null) {
    return <LoadingApp />;
  } else {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Suspense fallback={<Fragment />}>
            <Routes>
              <Route
                path='/*'
                element={
                  <ProtectedRoute>
                    <LoggedInComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/login'
                element={
                  <CheckLogin>
                    <LoggedOutComponent />
                  </CheckLogin>
                }
              />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
