import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { AppProvider } from './globalAppContext';
import axios from 'axios';

const queryClient = new QueryClient();

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      alert(
        'Your session has expired. Would you like to be redirected to the login page?INDEX'
      );
    } else {
      return Promise.reject(error);
    }
  }
);

ReactDOM.render(
  <AppProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </AppProvider>,
  document.getElementById('root')
);
