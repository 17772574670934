import React, { memo } from 'react';
//import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';
import Login from './Login';
//import Login1 from './Login';

function Routing() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
    </Routes>
  );
}

export default memo(Routing);
