import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, Paper } from '@mui/material';
import { withTheme } from '@mui/styles';
import { Controller } from 'react-hook-form';

const styles = (theme) => ({
  textField: {
    width: '100%',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingBottom: 0,
    // marginTop: 0,
    fontFamily: 'IBM Plex Sans, sans-serif',
    lineHeight: 2,
    fontWeight: 400,
    //flex: 1,
    fontSize: '1rem',
    backgroundColor: '#F3F6F9',
    border: 0,
    borderRadius: '8px',
    margin: 0,
    padding: 1.5,
    color: '#1A2027',
    '&.Mui-focused': {
      outline: '2px solid  #80BFFF',
      'outline-offset': '2px',
      backgroundColor: '#F3F6F9',
    },
    '&.MuiInputBase-input': {
      backgroundColor: '#F3F6F9',
    },
  },
  input: {
    color: 'red',
    backgroundColor: ' black',
  },
});

function FilledTextField(props) {
  const { theme, name, placeholder, control, sx, ...restProps } = props;
  const style = styles(theme);

  // const [value, setValue] = React.useState('');
  // const onChange = (event) => {
  //   setValue(event.target.value);
  // };

  return (
    <Paper
      //component='form'
      elevation={0}
      sx={{
        mb: 3,
        pt: 0.5,
        pb: 0.5,
        pl: 0,
        pr: 0,
        display: 'flex',
        alignItems: 'center',
        //width: { xs: 200, sm: 180, md: 400 },
        width: '100%',
        //height: '80%',
        //borderRadius: '8px',
        backgroundColor: 'white',
        borderColor: 'white',
        border: 0,
      }}
      {...restProps}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref, onBlur } }) => (
          <InputBase
            sx={style.textField}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'quick search' }}
            size='large'
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Paper>
  );
}

FilledTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default withTheme(FilledTextField);
